import { Step, StepProps, Steps } from "@/components/ui/steps-horizontal";
import { ReactNode, useState } from "react";
import { AxiosError } from "axios";
import Lottie from "lottie-react";
import loadingJson from "@/assets/lottie/loading.json";
import { track } from "@/lib/analytics";
import { DefaultValues } from "react-hook-form";

export interface WizardStep<T extends Record<string, any>> {
  name: string;
  description: ((v: Partial<T>) => ReactNode) | ReactNode;
  isComplete: (v: Partial<T>) => boolean;
  component: (props: {
    defaultValues: Partial<Partial<T>>;
    onSubmit: (v: Partial<T>) => Promise<void>;
    isLoading: boolean;
  }) => ReactNode;
}

interface WizardProps<T extends Record<string, any>> {
  isLoading: boolean;
  onSubmit: (v: T) => Promise<unknown>;
  steps: WizardStep<T>[];
  defaultValues?: DefaultValues<T>;
  title?: string;
}

export default function Wizard<T extends Record<string, any>>({
  isLoading,
  onSubmit,
  steps,
  defaultValues = {} as DefaultValues<T>,
}: WizardProps<T>) {
  const [values, setValues] = useState<Partial<T>>(defaultValues);
  const [currentStep, setCurrentStep] = useState(0);

  const performSubmit = (values: T) =>
    onSubmit(values)
      .then(() => {
        setValues({});
      })
      .catch((e: AxiosError) => {
        // @ts-ignore
        alert(e.response?.data?.message || "Something went wrong");
      });

  const getStatusForStep = (step: number): StepProps["status"] => {
    if (step < currentStep) {
      return "complete";
    }
    if (step > currentStep) {
      return "upcoming";
    }
    return "current";
  };

  const getStepBody = () => {
    const step = steps[currentStep];

    if (!step) {
      setCurrentStep(0);
      return null;
    }

    if (currentStep > 0) {
      const previousStep = steps[currentStep - 1];
      if (!previousStep!.isComplete(values)) {
        setCurrentStep(currentStep - 1);
        return null;
      }
    }

    return (
      <step.component
        defaultValues={values}
        isLoading={isLoading}
        onSubmit={(newValues) => {
          track("Wizard Step Completed", {
            step: currentStep,
            name: step.name,
          });
          setValues((prev) => ({ ...prev, ...newValues }));

          if (currentStep === steps.length - 1) {
            return performSubmit({
              ...values,
              ...newValues,
            } as T);
          }

          setCurrentStep((p) => p + 1);
          return Promise.resolve();
        }}
      />
    );
  };

  return (
    <div className="container space-y-8 px-1">
      {steps.length > 1 && (
        <Steps>
          {steps.map((step, index) => (
            <Step
              key={step.name}
              index={index}
              title={step.name}
              subtitle={
                typeof step.description === "function"
                  ? step.description(values)
                  : step.description
              }
              status={getStatusForStep(index)}
              isLast={index === steps.length - 1}
              onClick={() => {
                if (index < currentStep) {
                  setCurrentStep(index);
                }
              }}
            />
          ))}
        </Steps>
      )}

      {isLoading ? (
        <div className="flex h-screen max-h-96 w-full items-center justify-center">
          <Lottie animationData={loadingJson} loop />
        </div>
      ) : (
        getStepBody()
      )}
    </div>
  );
}

import { useEffect } from "react";
import {
  ColumnDef,
  getCoreRowModel,
  InitialTableState,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export function useManualTableState(
  initialSort: SortingState = [],
  initialFilters: Record<string, any> = {},
) {
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters);
  const [query, setQuery] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const { pageIndex, pageSize } = pagination;
  const [sorting, setSorting] = useState<SortingState>(initialSort);
  let sort: string | undefined = undefined;
  if (sorting[0]) {
    sort = sorting[0].desc ? `-${sorting[0].id}` : sorting[0].id;
  }

  return {
    filters,
    setFilters,
    query,
    setQuery,
    sorting,
    setSorting,
    pagination,
    setPagination,
    pageIndex,
    pageSize,
    sort,
  };
}

export type TableState = ReturnType<typeof useManualTableState>;

export type QueryData<T> = {
  data: T[];
  meta: { current_page: number; last_page: number; total: number };
};

export function useManualTableQuery<T, A extends Record<string, any> = object>(
  apiPath: string,
  tableState: TableState,
) {
  const { filters, pageIndex, pageSize, sort, query } = tableState;

  return useQuery(
    [apiPath, pageIndex, pageSize, sort, query, JSON.stringify(filters)],
    () =>
      axios
        .get<QueryData<T> & A>(apiPath, {
          params: {
            page: pageIndex + 1,
            count: pageSize,
            sort,
            query,
            ...filters,
          },
        })
        .then(({ data }) => data),
  );
}

export function useExport(apiPath: string, tableState: TableState) {
  const { sort, query } = tableState;
  return useMutation(() =>
    axios
      .get(apiPath, {
        responseType: "blob",
        params: {
          format: "xlsx",
          query,
          sort,
          ...tableState.filters,
        },
      })
      .then((res) => {
        const headerContentDisp = res.headers["content-disposition"];
        const filename =
          headerContentDisp &&
          headerContentDisp.split("filename=")[1].replace(/["']/g, "");
        const contentType =
          res.headers["content-type"] ||
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        const blob = new Blob([res.data], { type: contentType });
        const href = window.URL.createObjectURL(blob);

        const el = document.createElement("a");
        el.setAttribute("href", href);
        el.setAttribute("download", filename);
        el.click();

        return res;
      }),
  );
}

export function useManualTable<T>(
  tableState: TableState,
  query: UseQueryResult<QueryData<T>>,
  columns: ColumnDef<T, any>[],
  initialState: InitialTableState = {},
) {
  const table = useReactTable({
    data: query.data?.data || [],
    pageCount: query.data ? query.data.meta.last_page : 0,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: any) => row.id,
    onPaginationChange: tableState.setPagination,
    onSortingChange: tableState.setSorting,
    manualPagination: true,
    manualSorting: true,
    enableRowSelection: false,
    initialState,
    state: {
      pagination: tableState.pagination,
      sorting: tableState.sorting,
    },
  });

  useEffect(() => {
    table.resetPageIndex();
  }, [table, tableState.filters, tableState.query, tableState.sorting]);

  return table;
}

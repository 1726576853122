import { Button } from "@/components/ui/button";
import { useAppState, useSettings } from "@/context/AppContext";
import { Card } from "@/components/ui/card";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import AvailIcon from "@/assets/avail-circle.svg?react";
import { CheckIcon } from "@heroicons/react/20/solid";
import giftImage from "@/assets/gift-image.png";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

export default function GiftingGate({
  children,
}: {
  children: React.ReactNode;
}) {
  const { is_gifting: isGifting } = useSettings();
  const role = useAppState().membership.role;

  if (!isGifting && !role.includes("avail")) {
    return (
      <Card className="relative mx-auto flex max-w-4xl flex-col items-center justify-center rounded-xl p-6 pb-[400px] text-center">
        <div className="mb-4 inline-flex space-x-2">
          <div className="inline-flex size-16 items-center justify-center rounded-full border">
            <AvailIcon className="size-10" />
          </div>
          <div className="inline-flex size-16 items-center justify-center rounded-full border">
            <IntegrationIcon type="hubspot" size="size-10" />
          </div>
          <div className="inline-flex size-16 items-center justify-center rounded-full border">
            <IntegrationIcon type="salesforce" size="size-10" />
          </div>
        </div>
        <h2 className="text-3xl font-semibold">Unlock Quick & Easy Gifting</h2>
        <p className="mx-auto mt-3 max-w-2xl text-lg text-slate-500">
          Avail makes sending gifts to prospects, customers, employees, and
          partners simple and efficient. Talk to our team about enabling gifting
          for your account.
        </p>
        <ul className="mt-4 space-y-1">
          <li className="flex items-center">
            <CheckIcon className="mr-3 size-5 text-green-500" /> Send gifts to
            contacts directly from your CRM.
          </li>
          <li className="flex items-center">
            <CheckIcon className="mr-3 size-5 text-green-500" />
            Let recipients enter their own size and shipping information.
          </li>
          <li className="flex items-center">
            <CheckIcon className="mr-3 size-5 text-green-500" />
            Track the status of your gifts and get notified when they are
            delivered.
          </li>
          <li className="flex items-center">
            <CheckIcon className="mr-3 size-5 text-green-500" /> Set triggers
            for automated gifting based on milestones or events.
          </li>
        </ul>

        <Dialog>
          <DialogTrigger asChild>
            <Button className="relative z-10 mt-8">Get Started</Button>
          </DialogTrigger>
          <DialogContent className="max-w-5xl p-0">
            <iframe
              title="Gifting Meeting"
              style={{
                width: "100%",
                height: "690px",
              }}
              className="max-h-screen"
              src="https://meetings.hubspot.com/jonathan-hopkins/availswag?embed=true"
            />
          </DialogContent>
        </Dialog>

        <img src={giftImage} className="absolute bottom-0 h-[500px]" />
      </Card>
    );
  }
  return <>{children}</>;
}

import { useParams } from "react-router-dom";
import { useGetGiftLink } from "@/api/gifts";
import Container from "@/components/ui/container";
import Logo from "@/components/auth/Logo";
import { Skeleton } from "@/components/ui/skeleton";
import GiftLinkForm from "@/components/gifts/GiftLinkForm";
import { Card } from "@/components/ui/card";
import AddressBlock from "@/components/addresses/AddressBlock";
import EmptyState from "@/components/ui/empty-state";
import { AlertTriangleIcon, CheckCircleIcon } from "lucide-react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Address } from "avail-types";
import { imageUrl } from "@/lib/image";
import { useState } from "react";
import { Alert } from "@/components/ui/alert";

export default function GiftLink() {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetGiftLink(id!);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <main>
      <section className="mb-8 flex justify-center bg-white px-5 py-3 text-center shadow-sm">
        <Logo className="h-8" />
      </section>
      <Container className="max-w-4xl pb-8">
        {isError ? (
          <Alert variant="destructive">
            There was an error loading this page.
          </Alert>
        ) : isLoading ? (
          <Skeleton className="h-8 w-1/2" />
        ) : data ? (
          <div>
            <p className="mb-6 text-lg">
              Hi {data.first_name}, {data.created_by_user?.name ?? "Someone"} is
              sending you a gift!
            </p>

            <div className="grid gap-5 lg:grid-cols-2">
              {data.created_by_user && (
                <Card className="px-4 py-3">
                  <h3 className="mb-3 font-semibold">From</h3>

                  <div className="flex items-center gap-3">
                    {data.created_by_user.avatar && (
                      <img
                        src={data.created_by_user.avatar}
                        className="size-10 rounded-full"
                      />
                    )}
                    <div>
                      <p>{data.created_by_user.name}</p>
                      {data.created_by_user.title && (
                        <p className="text-muted-foreground">
                          {data.created_by_user.title}
                        </p>
                      )}
                      <p className="text-sm">
                        <a
                          href={`mailto:${data.created_by_user.email}`}
                          className="text-primary"
                        >
                          {data.created_by_user.email}
                        </a>
                      </p>
                    </div>
                  </div>
                </Card>
              )}

              <Card className="px-4 py-3">
                <h3 className="mb-3 font-semibold">To</h3>

                <p>
                  {data.first_name} {data.last_name}
                </p>
                <p className="text-sm text-muted-foreground">{data.email}</p>
              </Card>

              <Card className="col-span-full px-4 py-3">
                <h3 className="mb-3 font-semibold">Message</h3>
                <blockquote className="whitespace-pre-line">
                  {data.message}
                </blockquote>
              </Card>

              {data.items.map((i) => (
                <Card key={i.id} className="flex flex-col gap-2 px-4 py-3">
                  <h3 className="mb-3 font-semibold">Gift</h3>

                  <img
                    className="h-60 object-contain"
                    src={imageUrl(i.product.image, { w: 500 })}
                    alt=""
                  />

                  <div className="mb-2 mt-auto line-clamp-3 text-center font-semibold leading-tight text-black">
                    {i.product.name}
                  </div>

                  {!i.variant_id && (
                    <Button
                      className="bg-amber-500 hover:bg-amber-600"
                      size="xs"
                      onClick={() => setIsOpen(true)}
                    >
                      <AlertTriangleIcon className="mr-2 size-4" />
                      Please select a size
                    </Button>
                  )}
                </Card>
              ))}

              {data.fulfillment_order ? (
                <Card className="flex flex-col items-center justify-center px-4 py-3">
                  <EmptyState
                    Icon={CheckCircleIcon}
                    iconClassName="text-green-500"
                    title="Your gift is on the way!"
                    description="Once it ships, you'll receive a confirmation email with tracking information. If you have any questions, contact us."
                  />
                </Card>
              ) : (
                <Card className="flex flex-col px-4 py-3">
                  <h3 className="mb-3 font-semibold">Shipping Address</h3>

                  {data.address ? (
                    <button type="button" onClick={() => setIsOpen(true)}>
                      <AddressBlock address={data.address as Address} />
                    </button>
                  ) : (
                    <EmptyState
                      Icon={AlertTriangleIcon}
                      iconClassName="text-amber-500"
                      title="Missing Shipping Address"
                      description="Please provide a shipping address to receive your gift."
                    >
                      <Button
                        className="bg-amber-500 hover:bg-amber-600"
                        onClick={() => setIsOpen(true)}
                      >
                        Add Address
                      </Button>
                    </EmptyState>
                  )}
                </Card>
              )}
            </div>

            {!data.fulfillment_order && (
              <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="max-w-2xl">
                  <DialogTitle>Claim Gift</DialogTitle>
                  <GiftLinkForm gift={data} />
                </DialogContent>
              </Dialog>
            )}
          </div>
        ) : null}
      </Container>
    </main>
  );
}

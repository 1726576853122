import HubspotLogo from "@/assets/hubspot.svg?react";
import SalesforceLogo from "@/assets/salesforce.svg?react";
import ProliantLogo from "@/assets/proliant.svg?react";
import WorkdayLogo from "@/assets/workday.svg?react";

import { IntegrationType } from "avail-types";

interface IntegrationConfig {
  name: string;
  description: string;
  Icon: React.ElementType<{ className?: string }>;
}

export const INTEGRATIONS: Record<IntegrationType, IntegrationConfig> = {
  hubspot: {
    name: "HubSpot",
    description: "Send gifts to contacts directly from HubSpot.",
    Icon: HubspotLogo,
  },
  salesforce: {
    name: "Salesforce",
    description: "Send gifts to contacts directly from Salesforce.",
    Icon: SalesforceLogo,
  },
  proliant: {
    name: "Proliant",
    description: "Send gifts to employees synced from Proliant.",
    Icon: ProliantLogo,
  },
  workday: {
    name: "Workday",
    description: "Send gifts to employees synced from Workday.",
    Icon: WorkdayLogo,
  },
} as const;

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IntegrationType } from "avail-types";

export function useGetIntegrations() {
  return useQuery(
    ["integrations"],
    () =>
      axios
        .get<{
          data: {
            type: IntegrationType;
            is_connected: boolean;
          }[];
        }>("/integrations")
        .then(({ data }) => data.data),
    { staleTime: 1000 * 60 * 60 * 24 },
  );
}

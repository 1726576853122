import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Gift } from "avail-types";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { FormErrors } from "@/components/ui/form-errors";

const formSchema = z.object({
  items: z.array(
    z.object({
      id: z.string(),
      variant_id: z.coerce.number(),
    }),
  ),
});

export default function ChooseSizeForm({
  gift,
  defaultValues,
  onSubmit,
  isLoading,
}: {
  gift: Gift;
  defaultValues: Partial<z.infer<typeof formSchema>>;
  onSubmit: (values: z.infer<typeof formSchema>) => Promise<void>;
  isLoading: boolean;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {gift.items
          .filter((i) => i.product.variants.length > 1)
          .map((i, idx) => (
            <div className="flex items-center gap-4" key={i.id}>
              <img
                src={i.product.image || ""}
                alt={i.product.name}
                className="h-40 w-40 flex-shrink-0 object-contain"
              />

              <div className="flex-grow">
                <FormField
                  name={`items.${idx}.variant_id`}
                  control={form.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-normal">
                        Please provide your size for{" "}
                        <b className="font-medium">{i.product.name}</b>:
                      </FormLabel>
                      <Select
                        value={String(field.value)}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="w-[280px]">
                            <SelectValue placeholder="Select a size" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {i.product.variants.map((v) => (
                            <SelectItem key={v.id} value={String(v.id)}>
                              {v.size}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          ))}

        <FormErrors form={form} />

        <Button
          type="submit"
          isLoading={isLoading || form.formState.isSubmitting}
          className="mt-6"
        >
          Submit
        </Button>
      </form>
    </Form>
  );
}

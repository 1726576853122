import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export function DataList({
  children,
  className,
  ...other
}: HTMLAttributes<HTMLDListElement>) {
  return (
    <dl
      className={cn(
        "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2",
        className,
      )}
      {...other}
    >
      {children}
    </dl>
  );
}

export function DataCell({
  label,
  children,
  ...props
}: {
  label: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <dt className="text-sm font-medium leading-6">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-muted-foreground">
        {children}
      </dd>
    </div>
  );
}

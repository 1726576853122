import { GiftPayload } from "avail-types";
import { Loader } from "lucide-react";
import { useGetInventoryProducts } from "@/api/product";
import { cn } from "@/lib/utils";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useMemo, useState } from "react";
import { Input } from "@/components/ui/input";
import Pagination from "@/components/ui/pagination";
import AddProductIcon from "@/components/products/AddProductIcon";
import FulfillmentProductCard from "@/components/products/FulfillmentProductCard";

export default function GiftItemSelector({
  onChange,
  value,
}: {
  onChange: (c: GiftPayload["items"]) => void;
  value: GiftPayload["items"];
}) {
  const { data: products = [], isLoading } = useGetInventoryProducts();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const perPage = 10;

  const filteredProducts = useMemo(
    () =>
      products.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase()),
      ),
    [products, query],
  );

  const totalPages = Math.ceil(filteredProducts.length / perPage);

  useEffect(() => {
    setPage(0);
  }, [query]);

  return (
    <div className={cn(`relative space-y-8 py-1`)}>
      {isLoading ? (
        <Loader className="size-10 animate-spin" />
      ) : (
        <div>
          <div className="mb-4 flex justify-between">
            <Input
              type="search"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="e.g. Yeti Mug"
              className="max-w-xs"
            />
            <div>{value.length} selected</div>
          </div>

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-5">
            {filteredProducts
              .slice(page * perPage, (page + 1) * perPage)
              .map((product) => {
                const item = value.find((v) => v.product_id === product.id);

                return (
                  <button
                    type="button"
                    onClick={() =>
                      onChange(
                        item
                          ? value.filter((v) => v.product_id !== product.id)
                          : [
                              ...value,
                              {
                                product_id: product.id,
                                qty: 1,
                              },
                            ],
                      )
                    }
                    className="relative"
                  >
                    <FulfillmentProductCard product={product} />
                    {item && (
                      <CheckCircleIcon className="absolute right-2 top-2 z-[1] size-8 text-primary" />
                    )}
                    <AddProductIcon />
                  </button>
                );
              })}
          </div>

          {totalPages > 1 && (
            <Pagination
              pageIndex={page}
              totalPages={totalPages}
              canGetPrevious={page > 0}
              onPrevious={() => setPage((p) => p - 1)}
              canGetNextPage={page < totalPages - 1}
              onNext={() => setPage((p) => p + 1)}
            />
          )}
        </div>
      )}
    </div>
  );
}

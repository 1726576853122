import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { giftPayloadSchema } from "avail-types";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Alert } from "@/components/ui/alert";
import { useEffect } from "react";
import { useAppState } from "@/context/AppContext";
import Handlebars from "handlebars";
import { DEFAULT_MESSAGE, DEFAULT_SUBJECT } from "@/constants/gifts";

const formSchema = giftPayloadSchema.pick({
  send_email: true,
  first_name: true,
  email: true,
  subject: true,
  message: true,
});

type FormShape = z.infer<typeof formSchema>;

export default function GiftSend({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const {
    user,
    settings: { strings },
  } = useAppState();
  const values = form.watch();

  useEffect(() => {
    if (!values.subject && !values.message && values.send_email) {
      const variables = {
        ...values,
        user_name: user.name,
        user_email: user.email,
      };

      const subjectTemplate = Handlebars.compile(
        strings.giftSubject || DEFAULT_SUBJECT,
      );
      const messageTemplate = Handlebars.compile(
        strings.giftMessage || DEFAULT_MESSAGE,
      );
      form.setValue("subject", subjectTemplate(variables));
      form.setValue("message", messageTemplate(variables));
    }
  }, [strings, values, user, form]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>How do you want to send this gift?</Title>
            <Description>
              Choose how you want to send this gift to the recipient.
            </Description>
          </Toolbar>

          <div className="mt-4 max-w-4xl space-y-4">
            <FormField
              control={form.control}
              name="send_email"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <FormLabel htmlFor="existing-layout-switch">
                      Send Email to {values.email}
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />

            {form.watch("send_email") ? (
              <>
                <FormField
                  control={form.control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Subject</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value || ""} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          value={field.value || ""}
                          className="min-h-[200px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            ) : (
              <Alert variant="info">
                A link will be generated after the gift is created.
              </Alert>
            )}
          </div>

          <WizardSubmitButton>
            {values.send_email ? "Send Gift" : "Create Link"}
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}

import { CustomerSku } from "avail-types";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tag } from "lucide-react";
import { imageUrl } from "@/lib/image";
import { Badge } from "@/components/ui/badge";

interface SkuItemProps extends HTMLAttributes<HTMLDivElement> {
  sku: CustomerSku;
  qty?: number;
}

export default function SkuItem({
  sku,
  qty,
  className,
  children,
  ...other
}: SkuItemProps) {
  return (
    <div
      className={cn("flex flex-grow items-center gap-3", className)}
      {...other}
    >
      <Avatar>
        <AvatarImage
          src={imageUrl(sku.product.image, { h: 100, w: 100 })}
          alt=""
        />
        <AvatarFallback>
          <Tag />
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col gap-1 leading-tight">
        <div>{sku.description}</div>
        <div className="text-sm text-muted-foreground">{sku.sku}</div>
      </div>
      <div className="ml-auto flex items-center space-x-3">
        <Badge variant="gray">Inv: {sku.inventory_qty}</Badge>
        {qty != null && <Badge>{qty}</Badge>}
        {children}
      </div>
    </div>
  );
}

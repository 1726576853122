import { INTEGRATIONS } from "@/constants/integrations";
import { IntegrationType } from "avail-types";

export default function IntegrationIcon({
  type,
  size = "w-8 h-8",
}: {
  type: IntegrationType;
  size?: string;
}) {
  const { Icon } = INTEGRATIONS[type];
  return <Icon className={size} />;
}

import { ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { CheckIcon } from "@heroicons/react/20/solid";

export function Steps({
  className,
  role = "list",
  ...props
}: HTMLAttributes<HTMLOListElement>) {
  return (
    <nav aria-label="Progress">
      <ol
        className={cn(
          "divide-y divide-slate-200 rounded-md border border-slate-200 bg-card md:flex md:divide-y-0",
          className,
        )}
        role={role}
        {...props}
      />
    </nav>
  );
}

export interface StepProps {
  index: number;
  title: string;
  status: "complete" | "current" | "upcoming";
  onClick?: () => void;
  subtitle?: React.ReactNode;
  isLast?: boolean;
  href?: string;
}

export const Step = forwardRef(
  (
    { index, title, status, onClick, subtitle, isLast }: StepProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return (
      <li key={title} className="relative md:flex md:flex-1" ref={ref}>
        <button
          type="button"
          onClick={onClick}
          disabled={!onClick}
          className="group flex w-full items-center text-left"
        >
          <span className="flex items-center px-5 py-4 text-sm">
            <span className="flex-shrink-0">
              {status === "complete" ? (
                <span
                  className={cn(
                    "flex size-8 items-center justify-center rounded-full bg-primary",
                    {
                      "group-hover:bg-primary/80": !!onClick,
                    },
                  )}
                >
                  <CheckIcon className="size-5 text-white" aria-hidden="true" />
                </span>
              ) : status === "current" ? (
                <span className="flex size-8 items-center justify-center rounded-full border-2 border-primary">
                  <span className="text-primary">{index + 1}</span>
                </span>
              ) : (
                <span
                  className={cn(
                    "flex size-8 items-center justify-center rounded-full border-2 border-slate-200",
                    {
                      "group-hover:border-slate-400": !!onClick,
                    },
                  )}
                >
                  <span className="text-slate-500">{index + 1}</span>
                </span>
              )}
            </span>
            <div className="ml-4 flex flex-col items-start">
              <span
                className={cn("text-sm font-medium leading-tight", {
                  "text-slate-900": status === "current",
                  "text-primary": status === "complete",
                  "text-slate-500": status === "upcoming",
                  "group-hover:text-primary/80":
                    onClick && status === "complete",
                })}
              >
                {title}
              </span>
              {subtitle && (
                <span className="mt-1 text-sm text-slate-500">{subtitle}</span>
              )}
            </div>
          </span>
        </button>
        {!isLast ? (
          <>
            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute right-0 top-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-slate-200"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vectorEffect="non-scaling-stroke"
                  stroke="currentcolor"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </>
        ) : null}
      </li>
    );
  },
);

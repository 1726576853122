import { useTitle } from "@/lib/title";
import { useCreateGift } from "@/api/gifts";
import { GiftPayload } from "avail-types";
import pluralize from "pluralize";
import GiftItems from "@/components/gifts/create/GiftItems";
import GiftRecipient from "@/components/gifts/create/GiftRecipient";
import GiftSend from "@/components/gifts/create/GiftSend";
import { useAppContext } from "@/context/AppContext";
import { Navigate } from "react-router-dom";
import GiftingGate from "@/components/gifts/GiftingGate";
import Wizard, { WizardStep } from "@/components/wizard/Wizard";

const steps: WizardStep<GiftPayload>[] = [
  {
    name: "Items",
    description: (v) =>
      v.items && v.items.length > 0
        ? pluralize("item", v.items.length, true)
        : "What items would you like to include?",
    isComplete: (v) => Boolean(v.items && v.items.length > 0),
    component: GiftItems,
  },
  {
    name: "Recipient",
    description: (v) =>
      v.first_name
        ? `${v.first_name} ${v.last_name}`
        : "Who is the recipient of this gift?",
    isComplete: (v) => !!v.first_name,
    component: GiftRecipient,
  },
  {
    name: "Send",
    description: () => "How do you want to send this gift?",
    isComplete: (v) => v.send_email != null,
    component: GiftSend,
  },
];

export default function GiftCreate() {
  useTitle("Create Gift");
  const { membership } = useAppContext();

  const createGiftRequest = useCreateGift();

  if (!membership) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex-1 space-y-6">
      <GiftingGate>
        <Wizard
          isLoading={createGiftRequest.isLoading}
          onSubmit={createGiftRequest.mutateAsync}
          defaultValues={{
            send_email: true,
            items: [],
          }}
          steps={steps}
        />
      </GiftingGate>
    </div>
  );
}

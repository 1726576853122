import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import { useTitle } from "@/lib/title";
import { useGetIntegrations } from "@/api/integrations";
import { Card } from "@/components/ui/card";
import { INTEGRATIONS } from "@/constants/integrations";
import { Button } from "@/components/ui/button";
import { API_BASE_URL } from "@/constants/services";
import { CheckCircleIcon, ExternalLinkIcon } from "lucide-react";
import { useAppState } from "@/context/AppContext";

export default function Integrations() {
  useTitle("Integrations");
  const { data = [], isLoading } = useGetIntegrations();
  const { membership } = useAppState();
  return (
    <div className="flex-1 space-y-6">
      <Toolbar>
        <Title>Integrations</Title>
      </Toolbar>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
          {data.map((integration) => {
            const { name, description, Icon } = INTEGRATIONS[integration.type];

            return (
              <Card className="flex flex-col items-center">
                <div className="flex w-full items-center justify-center border-b bg-primary/5 p-4">
                  <Icon className="h-24 w-24" />
                </div>
                <div className="px-5 py-4">
                  <h4 className="text-xl font-semibold">{name}</h4>
                  <p className="mb-5 mt-1 text-sm text-muted-foreground">
                    {description}
                  </p>

                  <Button className="w-full" asChild variant="outline">
                    <a
                      href={`${API_BASE_URL}customer-api/integrations/${integration.type}?selected_customer_id=${membership.customer.id}`}
                    >
                      {integration.is_connected && (
                        <CheckCircleIcon className="mr-2 h-5 w-5 text-green-500" />
                      )}
                      {integration.is_connected ? "Connected" : "Connect"}
                      {!integration.is_connected && (
                        <ExternalLinkIcon className="ml-2 h-5 w-5 text-primary" />
                      )}
                    </a>
                  </Button>
                </div>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
}

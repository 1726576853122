import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { imageUrl } from "@/lib/image";
import { curr } from "@/lib/curr";
import pluralize from "pluralize";
import { CustomerProduct } from "avail-types";

export default function FulfillmentProductCard({
  product,
  withoutPrice,
}: {
  product: CustomerProduct;
  withoutPrice?: boolean;
}) {
  const price = product.variants.reduce((prev, v) => {
    const value = v.value ?? v.average_purchase_price ?? 0;
    if (value != null && value < prev) {
      return value;
    }
    return prev;
  }, 9999999);

  return (
    <Card
      className={cn(
        "relative pt-4 text-xs transition-all hover:shadow-md sm:text-sm",
      )}
    >
      <div className="mb-20">
        <img
          className="mx-auto h-40 w-full object-contain md:h-64"
          src={imageUrl(product.image, { w: 500 })}
          alt={product.name}
        />
      </div>
      <div className="absolute bottom-0 w-full bg-white px-3 py-4 md:px-4">
        <div className="mb-2 line-clamp-3 font-semibold leading-tight text-black">
          {product.name}
        </div>
        {!withoutPrice && (
          <div className="text-xs text-slate-700">
            As low as <b className="font-medium">{curr(price)} ea</b>
          </div>
        )}
        <div className="mt-0.5 text-xs text-slate-700">
          {pluralize("size", product.variants.length, true)}
        </div>
      </div>
    </Card>
  );
}

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CartProduct, CustomerProduct, CustomerSku } from "avail-types";
import groupBy from "lodash/groupBy";

export function useSearchProducts({
  query,
  filters = {},
  page = 0,
  all = false,
}: {
  query: string;
  filters?: { [key: string]: string | undefined };
  page?: number;
  all?: boolean;
}) {
  const queryClient = useQueryClient();
  return useQuery(
    ["searchProducts", query, JSON.stringify(filters), page],
    () =>
      axios
        .get<{
          data: CartProduct[];
          meta: { current_page: number; last_page: number };
          facets: Record<string, Record<string, number>>;
        }>("/products", { params: { query, filters, page: page + 1, all } })
        .then(({ data }) => {
          data.data.forEach((product) => {
            queryClient.setQueryData(["productById", product.id], product);
          });
          return data;
        }),
  );
}

export function useGetProduct(slug: string) {
  return useQuery(["product", slug], () =>
    axios
      .get<{ data: CartProduct; colors: CartProduct[] }>(`/products/${slug}`)
      .then(({ data }) => data),
  );
}

export function useGetProductById(id?: number | null) {
  return useQuery(
    ["productById", id],
    () =>
      axios.get<CartProduct>(`/products/by-id/${id}`).then(({ data }) => data),
    { staleTime: Infinity, refetchOnMount: false, enabled: id != null },
  );
}

export function useGetInventoryProducts() {
  return useQuery<CustomerProduct[]>(["inventoryProducts"], () =>
    axios.get<{ data: CustomerSku[] }>("/variants").then(({ data }) =>
      Object.values(groupBy(data.data, (v) => v.product.id)).map((skus) => ({
        id: skus[0]!.product.id,
        name: skus[0]!.product.name,
        image: skus[0]!.product.image,
        variants: skus,
      })),
    ),
  );
}

import AddressFields from "@/components/addresses/AddressFields";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addressPayloadSchema } from "avail-types";
import { z } from "zod";
import { FormErrors } from "@/components/ui/form-errors";

const formSchema = z.object({
  address: addressPayloadSchema,
});

type FormShape = z.infer<typeof formSchema>;

export default function GiftAddressForm({
  defaultValues,
  onSubmit,
  isLoading,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
  isLoading: boolean;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="mb-3">
          Please enter the address you would like this gift shipped to:
        </p>

        <AddressFields form={form} />

        <FormErrors form={form} />

        <Button
          type="submit"
          className="mt-6"
          isLoading={isLoading || form.formState.isSubmitting}
        >
          Submit
        </Button>
      </form>
    </Form>
  );
}

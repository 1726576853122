import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useTitle } from "@/lib/title";
import GiftsTable from "@/components/gifts/GiftsTable";
import GiftingGate from "@/components/gifts/GiftingGate";
import { SendIcon } from "lucide-react";
import BulkUploadForm from "@/components/ui/bulk-upload-form";
import { useQueryClient } from "@tanstack/react-query";

export default function Gifts() {
  useTitle("Gifting");
  const queryClient = useQueryClient();

  const actions = (
    <>
      <div className="flex">
        <Button asChild className="rounded-r-none">
          <Link to="/gifts/create">
            <SendIcon className="mr-2 size-4" />
            Send Gift
          </Link>
        </Button>
        <BulkUploadForm
          formName="gifts"
          templateUrl="bulk_gift_template"
          postCb={() => queryClient.invalidateQueries(["/gifts"])}
        />
      </div>
    </>
  );

  return (
    <GiftingGate>
      <div className="flex-1 space-y-6">
        <Toolbar actions={actions}>
          <Title>Gifts</Title>
        </Toolbar>

        <GiftsTable />
      </div>
    </GiftingGate>
  );
}

import { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useSendGiftReminder } from "@/api/gifts";
import pluralize from "pluralize";
import { useToast } from "@/components/ui/use-toast";

export default function GiftSendReminderDialog({
  ids = [],
  children,
}: {
  ids: string[];
  children?: ReactNode;
}) {
  const { toast } = useToast();
  const sendGiftReminder = useSendGiftReminder();
  const [open, setOpen] = useState(false);

  const handleSendReminder = () => {
    sendGiftReminder.mutateAsync(ids).then(() => {
      setOpen(false);
      toast({
        title: `Successfully sent ${pluralize("reminder", ids.length, true)}`,
        variant: "success",
      });
    });
  };

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Send Gift {pluralize("Reminder", ids.length)}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to send a reminder for{" "}
          {pluralize("this", ids.length)} {pluralize("gift", ids.length)}?
        </DialogDescription>

        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="default"
            isLoading={sendGiftReminder.isLoading}
            onClick={handleSendReminder}
          >
            Send {pluralize("Reminder", ids.length)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useCancelGift } from "@/api/gifts";
import { Gift } from "avail-types";

export default function GiftCancelDialog({
  gift,
  children,
}: {
  gift: Gift;
  children?: ReactNode;
}) {
  const cancelGift = useCancelGift(gift.id);
  const [open, setOpen] = useState(false);

  const handleCancel = () =>
    cancelGift.mutateAsync().then(() => {
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel Gift</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to cancel this gift?
        </DialogDescription>

        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            No
          </Button>
          <Button
            variant="destructive"
            isLoading={cancelGift.isLoading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

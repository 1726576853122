import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { giftPayloadSchema } from "avail-types";
import { Input } from "@/components/ui/input";
import { useSearchIntegrationContacts } from "@/api/gifts";
import { AutoComplete } from "@/components/ui/autocomplete";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { useGetIntegrations } from "@/api/integrations";

const formSchema = giftPayloadSchema.pick({
  first_name: true,
  last_name: true,
  company: true,
  email: true,
  external_source: true,
  external_id: true,
});

type FormShape = z.infer<typeof formSchema>;

function FirstNameInput({
  field,
  onContact,
}: {
  field: ControllerRenderProps<FormShape, "first_name">;
  onContact: (contact: Partial<FormShape>) => void;
}) {
  const { data, isLoading, isFetching } = useSearchIntegrationContacts(
    field.value,
  );

  return (
    <AutoComplete
      value={field.value}
      onValueChange={field.onChange}
      emptyMessage="No contacts found"
      loadingMessage="Searching contacts from your integrations..."
      onOptionSelect={(option) => {
        const contact = data?.find((c) => c.id === option.value);
        if (contact) {
          onContact(contact);
        }
      }}
      options={data?.map((c) => ({
        value: c.id,
        label: (
          <div className="flex w-full items-center">
            <div className="flex-grow">
              <div className="text-sm">
                {c.first_name} {c.last_name}
              </div>
              <div className="text-xs text-muted-foreground">{c.email}</div>
            </div>
            <IntegrationIcon type={c.external_source} size="size-6" />
          </div>
        ),
      }))}
      isLoading={isLoading && isFetching}
    />
  );
}

export default function GiftRecipient({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const { data = [] } = useGetIntegrations();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>Who is the recipient of this gift?</Title>
            <Description>
              Enter the recipient's information to send them a gift.
            </Description>
          </Toolbar>

          <div className="grid max-w-4xl grid-cols-2 gap-5">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    {data.some((i) => i.is_connected) ? (
                      <FirstNameInput
                        field={field}
                        onContact={(contact) =>
                          Object.entries(contact).forEach(([key, value]) => {
                            form.setValue(
                              key as keyof FormShape,
                              value as string | null,
                            );
                          })
                        }
                      />
                    ) : (
                      <Input {...field} value={field.value || ""} />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="company"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Company</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value || ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <WizardSubmitButton>Next</WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}

import { ReactNode } from "react";
import { ColumnDef, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerSku } from "avail-types";
import { Archive, Send } from "lucide-react";
import { Button } from "@/components/ui/button";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import Toolbar from "@/components/ui/toolbar";
import { UseQueryResult } from "@tanstack/react-query";
import { curr } from "@/lib/curr";
import { formatDate } from "@/lib/dates";
import { Badge } from "@/components/ui/badge";
import ArchiveSkuButton from "@/components/inventory/ArchiveSkuButton";
import EditSkuButton from "@/components/inventory/EditSkuButton";
import InventoryHistoryButton from "@/components/inventory/InventoryHistoryButton";
import ReorderSheet from "@/components/inventory/ReorderSheet";
import { Link } from "react-router-dom";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { imageUrl } from "@/lib/image";
import pluralize from "pluralize";

const COLUMNS: ColumnDef<CustomerSku>[] = [
  {
    accessorKey: "image",
    enableHiding: false,
    header: "",
    cell: ({ getValue }) => (
      <img
        src={imageUrl(getValue<string | null>(), { h: 100, w: 100 })}
        alt=""
        className="size-8"
      />
    ),
  },
  {
    accessorKey: "sku",
    header: "SKU",
    cell: ({ getValue }) => (
      <span className="font-medium">{getValue<string>()}</span>
    ),
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "calculated_value",
    header: "Value",
    cell: ({ getValue }) => <span>{curr(getValue<number>())}</span>,
    enableSorting: false,
  },
  {
    accessorKey: "inventory_qty",
    header: "Inventory",
  },
  {
    accessorKey: "sold_qty",
    header: "Used",
  },
  {
    accessorKey: "last_pulled_at",
    cell: ({ getValue }) => <span>{formatDate(getValue<string>())}</span>,
    header: "Last Pulled",
  },
  {
    accessorKey: "last_restocked_at",
    cell: ({ getValue }) => <span>{formatDate(getValue<string>())}</span>,
    header: "Last Restocked",
  },
  {
    accessorKey: "pulled_qty",
    header: "Pulled",
  },
  {
    accessorKey: "stocked_qty",
    header: "Stocked",
  },
  {
    id: "levels",
    cell: ({ row }) => {
      const sku = row.original;
      return (
        <div className="flex items-center space-x-3">
          {sku.inventory_scrapped > 0 && (
            <Badge variant="amber">{sku.inventory_scrapped} scrapped</Badge>
          )}

          {sku.inventory_on_order > 0 && (
            <Badge variant="teal">{sku.inventory_on_order} on order</Badge>
          )}

          {sku.alert_level && sku.inventory_qty < sku.alert_level ? (
            <Badge variant="destructive">Low</Badge>
          ) : null}

          {sku.alert_level != null && <Badge>Min: {sku.alert_level}</Badge>}

          {sku.par_level != null && <Badge>Par: {sku.par_level}</Badge>}
        </div>
      );
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="flex gap-3 text-slate-500">
        <SimpleTooltip tooltip="Send Swag">
          <Link to={`/send?variant=${row.original.id}`}>
            <Send className="size-5" />
          </Link>
        </SimpleTooltip>
        <EditSkuButton sku={row.original} />

        <InventoryHistoryButton sku={row.original} />

        {row.original.inventory_qty === 0 && (
          <ArchiveSkuButton skus={[row.original]} title="Archive SKU">
            <button className="align-center flex">
              <Archive className="size-5 text-slate-500" />
            </button>
          </ArchiveSkuButton>
        )}
      </div>
    ),
  },
];
export default function InventoryTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerSku>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerSku>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS, {
    columnVisibility: {
      last_pulled_at: false,
      last_restocked_at: false,
      pulled_qty: false,
      stocked_qty: false,
      sold_qty: false,
    },
  });
  table.setOptions((prev) => ({
    ...prev,
    enableRowSelection: (row) => row.original.inventory_qty > 0,
  }));
  const { rows: selected } = table.getSelectedRowModel();
  const numRowsSelected = selected.length;
  const selectedHasInventory = selected.some(
    (row) => row.original.inventory_qty > 0,
  );

  return (
    <div>
      {numRowsSelected > 0 && (
        <Toolbar
          className="py-2"
          actions={
            numRowsSelected ? (
              <>
                <ReorderSheet skus={selected.map((sku) => sku.original)} />
                <ArchiveSkuButton
                  disabled={selectedHasInventory}
                  onArchive={() => table.toggleAllPageRowsSelected(false)}
                  skus={selected.map((row) => row.original)}
                  title="Archive SKUs"
                >
                  <Button variant="destructive">
                    <Archive className="mr-2 size-5" />
                    Archive SKU{selected.length !== 1 && "s"}
                  </Button>
                </ArchiveSkuButton>
              </>
            ) : null
          }
        >
          {pluralize("SKU", numRowsSelected, true)} selected
        </Toolbar>
      )}
      <DataTable
        header={header && header(table)}
        table={table}
        isLoading={query.isLoading}
      />
    </div>
  );
}

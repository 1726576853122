import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField } from "@/components/ui/form";
import { useSettings } from "@/context/AppContext";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { giftPayloadSchema } from "avail-types";
import GiftItemSelector from "@/components/gifts/GiftItemSelector";
import EmptyState from "@/components/ui/empty-state";
import pluralize from "pluralize";

const formSchema = giftPayloadSchema.pick({
  items: true,
});

type FormShape = z.infer<typeof formSchema>;

export default function GiftItems({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const numItems = form.watch("items")?.length || 0;
  const settings = useSettings();

  if (!settings.is_fulfillment) {
    return (
      <EmptyState
        title="No items in inventory"
        description="Get started by creating a project to add items to your inventory."
      />
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>What items do you want to send?</Title>
            <Description>
              Choose from any of the items in your inventory to send as a gift.
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="items"
            render={({ field }) => (
              <GiftItemSelector
                value={field.value || []}
                onChange={field.onChange}
              />
            )}
          />

          <WizardSubmitButton disabled={numItems === 0}>
            Choose {pluralize("Item", numItems, true)}
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}

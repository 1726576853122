import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import startCase from "lodash/startCase";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Alert } from "@/components/ui/alert";
import Link from "@/components/ui/link";
import { ChevronDown } from "lucide-react";

export default function BulkUploadForm({
  formName,
  postCb,
  templateUrl,
}: {
  formName: string;
  postCb?: () => void;
  templateUrl: string;
}) {
  const postUrl = formName.toLowerCase().split(" ").join("-");
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0];
    setError("");
    if (!f) return;
    setFile(f);
  };

  const resetForm = () => {
    setFile(null);
    setLoading(false);
    setError("");
    setOpen(false);
  };

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (!file) {
      setError("Please select a file");
      setLoading(false);
      return;
    }

    formData.append("file", file);

    return axios
      .post(`/${postUrl}/bulk-request`, formData)
      .then(() => {
        resetForm();
        if (postCb) {
          postCb();
        }
        navigate(`/${postUrl}`);
      })
      .catch((error) => {
        setLoading(false);
        setError(
          error.response.data.message ||
            "An error occurred. Please try again later.",
        );
      });
  }

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="rounded-l-none border-l border-l-black/25 px-3">
            <ChevronDown className="size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setOpen(true)}>
            Bulk Upload
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload {startCase(formName)}</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            You can bulk upload {formName.toLowerCase()} with an excel sheet.
            Please download the template below to get started.
          </DialogDescription>
          <Link href={`/${templateUrl}.xlsx`} target="_blank">
            Download Template
          </Link>
          <form onSubmit={onSubmit} className="space-y-4">
            <Input
              type="file"
              onChange={handleFileUpload}
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            {error && <Alert variant="destructive">{error}</Alert>}
            <DialogFooter>
              <Button variant="outline" onClick={() => resetForm()}>
                Cancel
              </Button>
              <Button isLoading={loading} type="submit">
                Upload
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

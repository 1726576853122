import { Link } from "react-router-dom";
import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { createColumnHelper } from "@tanstack/react-table";
import Toolbar from "@/components/ui/toolbar";
import { Gift } from "avail-types";
import { useMemo } from "react";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data-table";
import { formatDate } from "@/lib/dates";
import GiftStatusBadge from "@/components/gifts/GiftStatusBadge";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import { canCancelGift } from "@/lib/gifts";
import GiftSendReminderDialog from "@/components/gifts/GiftSendReminderDialog";
import pluralize from "pluralize";
import { Mail } from "lucide-react";

const columnHelper = createColumnHelper<Gift>();

export default function GiftsTable() {
  const tableState = useManualTableState([
    {
      id: "created_at",
      desc: true,
    },
  ]);
  const giftsQuery = useManualTableQuery<Gift>("/gifts", tableState);
  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        header: "First Name",
        cell: ({ getValue, row }) => (
          <Link to={`/gifts/${row.id}`} className="font-medium">
            {getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor("last_name", { header: "Last Name" }),
      columnHelper.accessor("company", { header: "Company" }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: ({ row, getValue }) => (
          <div className="flex items-center gap-2">
            {getValue()}
            {row.original.external_source && row.original.external_url && (
              <a
                href={row.original.external_url}
                target="_blank"
                rel="noreferrer"
              >
                <IntegrationIcon
                  type={row.original.external_source}
                  size="size-5"
                />
              </a>
            )}
          </div>
        ),
      }),
      columnHelper.accessor("created_by_user.name", {
        header: "Sender",
      }),
      columnHelper.accessor("created_at", {
        header: "Sent At",
        cell: ({ getValue }) => formatDate(getValue()),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ({ row }) => <GiftStatusBadge gift={row.original} />,
      }),
    ],
    [],
  );
  const table = useManualTable(tableState, giftsQuery, columns);
  table.setOptions((prev) => ({
    ...prev,
    enableRowSelection: (row) => canCancelGift(row.original),
  }));
  const { rows: selected } = table.getSelectedRowModel();
  const selectedIds = selected.map((row) => row.id);

  return (
    <div>
      {selectedIds.length > 0 && (
        <Toolbar
          className="mb-2"
          actions={
            <GiftSendReminderDialog ids={selectedIds}>
              <Button variant="outline">
                <Mail className="mr-1.5 size-4" />
                Send {pluralize("Reminder", selectedIds.length)}
              </Button>
            </GiftSendReminderDialog>
          }
        >
          {pluralize("Gift", selectedIds.length, true)} selected
        </Toolbar>
      )}
      <DataTable table={table} isLoading={giftsQuery.isLoading} />
    </div>
  );
}

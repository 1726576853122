import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  AddressPayload,
  Gift,
  GiftPayload,
  IntegrationType,
  Paginated,
} from "avail-types";
import { useNavigate } from "react-router-dom";
import useDebounce from "@/hooks/useDebounce";

export function useGetGifts() {
  return useQuery(["gifts"], () =>
    axios.get<Paginated<Gift>>("gifts").then(({ data }) => data),
  );
}

export function useGetGift(id: string) {
  return useQuery(["gift", id], () =>
    axios.get<Gift>(`gifts/${id}`).then(({ data }) => data),
  );
}

export function useCreateGift() {
  const navigate = useNavigate();

  return useMutation((payload: GiftPayload) =>
    axios.post<Gift>("gifts", payload).then(({ data }) => {
      navigate(`/gifts/${data.id}?created=1`);
      return data;
    }),
  );
}

export interface IntegrationContact {
  id: string;
  external_id: string;
  external_source: IntegrationType;
  first_name: string;
  last_name: string;
  email: string;
  company?: string | null;
}

export function useSearchIntegrationContacts(query: string) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery(
    ["integrationContacts", debouncedQuery],
    () =>
      axios
        .get<{ data: IntegrationContact[] }>("integration-contacts", {
          params: { query: debouncedQuery },
        })
        .then(({ data }) => data.data),
    {
      enabled: !!debouncedQuery,
      staleTime: Infinity,
    },
  );
}

export function useGetGiftLink(id: string) {
  return useQuery(["giftLink", id], () =>
    axios.get<Gift>(`gift-links/${id}`).then(({ data }) => data),
  );
}

export interface RedeemPayload {
  address: AddressPayload;
  items: { id: string; variant_id: number }[];
}

export function useRedeemGift(id: string) {
  const queryClient = useQueryClient();

  return useMutation((payload: RedeemPayload) =>
    axios.put<Gift>(`gift-links/${id}`, payload).then(({ data }) => {
      queryClient.setQueryData(["giftLink", id], data);
      return data;
    }),
  );
}

export function useCancelGift(id: string) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(() =>
    axios.post(`gifts/${id}/cancel`).then(() => {
      queryClient.invalidateQueries(["gifts"]);
      navigate("/gifts");
    }),
  );
}

export function useSendGiftReminder() {
  const queryClient = useQueryClient();

  return useMutation((ids: string[]) =>
    axios.post<Gift>("gifts/send-reminder", { ids }).then(({ data }) => {
      queryClient.invalidateQueries(["gifts"]);
      return data;
    }),
  );
}

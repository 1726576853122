import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import { useTitle } from "@/lib/title";
import { useGetGift } from "@/api/gifts";
import { useParams, useSearchParams } from "react-router-dom";
import { DataCell, DataList } from "@/components/ui/data-list";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  BanIcon,
  CopyIcon,
  ExternalLinkIcon,
  MoreVerticalIcon,
  Send,
} from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import Lottie from "lottie-react";
import confetti from "@/assets/lottie/confetti2.json";
import { useCallback, useEffect, useState } from "react";
import GiftStatusSection from "@/components/gifts/GiftStatusSection";
import pluralize from "pluralize";
import IntegrationIcon from "@/components/integrations/IntegrationIcon";
import FulfillmentProductCard from "@/components/products/FulfillmentProductCard";
import AddressBlock from "@/components/addresses/AddressBlock";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import GiftCancelDialog from "@/components/gifts/GiftCancelDialog";
import GiftRedeemReminderDialog from "@/components/gifts/GiftSendReminderDialog";
import { canCancelGift } from "@/lib/gifts";

export default function GiftDetail() {
  const { id } = useParams();
  const { data } = useGetGift(id!);
  const { toast } = useToast();
  useTitle("Gift");

  const [showConfetti, setShowConfetti] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const onShowConfetti = useCallback(() => {
    setShowConfetti(true);
  }, [setShowConfetti]);

  useEffect(() => {
    if (searchParams.has("created")) {
      onShowConfetti();
      setSearchParams((prev) => {
        prev.delete("created");
        return prev;
      });
    }
  }, []);

  const actions = data && (
    <>
      <div className="flex">
        <Input
          value={data.url}
          className="w-72 rounded-r-none text-sm"
          readOnly
        />
        <Button
          variant="outline"
          className="rounded-none border-l-0 border-r-0"
          onClick={() =>
            window.navigator.clipboard.writeText(data.url).then(() => {
              toast({
                title: "Link Copied",
                description: "The gift URL has been copied to your clipboard.",
              });
            })
          }
        >
          <CopyIcon className="h-5 w-5" />
        </Button>
        <Button variant="outline" className="rounded-l-none" asChild>
          <a href={data.url} target="_blank" rel="noreferrer">
            <ExternalLinkIcon className="h-5 w-5" />
          </a>
        </Button>
      </div>
      {canCancelGift(data) && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="print:hidden">
            <Button size="icon" variant="outline">
              <MoreVerticalIcon className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <GiftCancelDialog gift={data}>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                <BanIcon className="mr-2 h-4 w-4" />
                Cancel
              </DropdownMenuItem>
            </GiftCancelDialog>
            <GiftRedeemReminderDialog ids={[data.id]}>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                <Send className="mr-2 h-4 w-4" />
                Send Reminder
              </DropdownMenuItem>
            </GiftRedeemReminderDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Gift {data && ` to ${data.name}`}</Title>

        {data && (
          <p className="text-muted-foreground">
            Sent by {data.created_by_user?.name || "Unknown"}
          </p>
        )}
      </Toolbar>

      {data && (
        <div className="space-y-6">
          <GiftStatusSection gift={data} />

          <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3">
            <Card className="px-5 py-4">
              <div className="mb-4 flex items-center gap-3">
                <h3 className="text-xl font-semibold">Recipient</h3>
                {data.external_source && data.external_url && (
                  <a href={data.external_url!} target="_blank" rel="noreferrer">
                    <IntegrationIcon
                      type={data.external_source}
                      size="size-6"
                    />
                  </a>
                )}
              </div>
              <DataList>
                <DataCell label="First Name">{data.first_name}</DataCell>
                <DataCell label="Last Name">{data.last_name}</DataCell>
                {data.company && (
                  <DataCell label="Company" className="col-span-2">
                    {data.company}
                  </DataCell>
                )}
                <DataCell label="Email" className="col-span-2">
                  {data.email}
                </DataCell>
              </DataList>
            </Card>

            {data.send_email ? (
              <Card className="px-5 py-4">
                <h3 className="mb-4 text-xl font-semibold">Email</h3>
                <DataList className="sm:grid-cols-1">
                  <DataCell label="Subject">{data.subject}</DataCell>
                  <DataCell label="Message">
                    <div className="whitespace-pre-line">{data.message}</div>
                  </DataCell>
                </DataList>
              </Card>
            ) : null}

            <Card className="px-5 py-4">
              <h3 className="mb-4 text-xl font-semibold">Shipping Details</h3>
              <DataList className="sm:grid-cols-1">
                <DataCell label="Address">
                  {data.fulfillment_order ? (
                    <AddressBlock address={data.fulfillment_order.address} />
                  ) : (
                    <p className="text-muted-foreground">
                      Awaiting address from recipient
                    </p>
                  )}
                </DataCell>
                <DataCell label="Tracking">
                  {data.fulfillment_order?.shipments[0] ? (
                    <a
                      href={
                        data.fulfillment_order.shipments[0].tracking_url || "#"
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium text-primary"
                    >
                      {data.fulfillment_order.shipments[0].tracking_number ||
                        "No tracking number"}
                    </a>
                  ) : (
                    <p className="text-muted-foreground">Not shipped yet</p>
                  )}
                </DataCell>
              </DataList>
            </Card>
          </div>
          <section>
            <h3 className="mb-2 text-xl font-semibold">
              {pluralize("Gift", data.items.length)}
            </h3>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {data.items.map((item) => (
                <FulfillmentProductCard key={item.id} product={item.product} />
              ))}
            </div>
          </section>
        </div>
      )}

      {showConfetti && (
        <div className="pointer-events-none absolute inset-0">
          <Lottie
            animationData={confetti}
            className="h-full w-full"
            onLoopComplete={() => setShowConfetti(false)}
          />
        </div>
      )}
    </div>
  );
}

import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import FulfillmentOrderTable from "@/components/fulfillmentOrders/FulfillmentOrderTable";
import BulkUploadForm from "@/components/ui/bulk-upload-form";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useTitle } from "@/lib/title";

export default function FulfillmentOrders() {
  useTitle("Fulfillment Orders");

  const actions = (
    <div className="flex">
      <Button asChild className="rounded-r-none">
        <Link to="/send">Send Swag</Link>
      </Button>
      <BulkUploadForm
        formName="Fulfillment Orders"
        templateUrl="bulk_fulfillment_template"
      />
    </div>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Fulfillment Orders</Title>
      </Toolbar>

      <FulfillmentOrderTable />
    </div>
  );
}

import {
  flexRender,
  SortDirection,
  Table as ReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableBodySkeleton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import TablePagination from "@/components/ui/table-pagination";
import { Card, CardHeader } from "@/components/ui/card";
import { ReactNode } from "react";
import { Checkbox } from "@/components/ui/checkbox";

function getIcon(sortDir: false | SortDirection, className: string) {
  const classes = `ml-2 size-4 ${className}`;
  if (sortDir === "asc") {
    return <ArrowUp className={classes} />;
  }
  if (sortDir === "desc") {
    return <ArrowDown className={classes} />;
  }

  // This is just to hold space
  return <ArrowUpDown className={`${classes} opacity-0`} />;
}

export function DataTable<T>({
  table,
  isLoading,
  header,
}: {
  table: ReactTable<T>;
  isLoading: boolean;
  header?: ReactNode;
}) {
  const someCanSelect = table.getRowModel().rows.some((r) => r.getCanSelect());

  return (
    <Card>
      {header && <CardHeader className="px-2 pb-4 pt-2">{header}</CardHeader>}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {someCanSelect && (
                <TableCell>
                  <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                      table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label="Select all"
                  />
                </TableCell>
              )}
              {headerGroup.headers.map((header) => {
                const inner = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    );

                return (
                  <TableHead key={header.id}>
                    {header.column.getCanSort() ? (
                      <button
                        className="group inline-flex items-center"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {inner}
                        {getIcon(
                          header.column.getIsSorted(),
                          "group-hover:hidden",
                        )}
                        {getIcon(
                          header.column.getNextSortingOrder(),
                          "hidden group-hover:inline-block",
                        )}
                      </button>
                    ) : (
                      inner
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        {isLoading ? (
          <TableBodySkeleton
            numColumns={table.getAllColumns().length}
            numRows={3}
          />
        ) : (
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {someCanSelect && (
                    <TableCell>
                      {row.getCanSelect() && (
                        <Checkbox
                          checked={row.getIsSelected()}
                          onCheckedChange={(value) =>
                            row.toggleSelected(!!value)
                          }
                          aria-label="Select row"
                        />
                      )}
                    </TableCell>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                {/* @ts-ignore */}
                <TableCell colSpan="100%" className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <div className="border-t px-5">
        {!isLoading && <TablePagination table={table} />}
      </div>
    </Card>
  );
}

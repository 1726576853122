import { Gift } from "avail-types";
import { RedeemPayload, useRedeemGift } from "@/api/gifts";
import Wizard, { WizardStep } from "@/components/wizard/Wizard";
import GiftAddressForm from "@/components/gifts/links/GiftAddressForm";
import ChooseSizeForm from "@/components/gifts/links/ChooseSizeForm";

export default function GiftLinkForm({ gift }: { gift: Gift }) {
  const redeemGift = useRedeemGift(gift.id);

  const steps: WizardStep<RedeemPayload>[] = [
    {
      name: "Address",
      description: "Where should we send this gift?",
      component: GiftAddressForm,
      isComplete: (v) => Boolean(v.address),
    },
  ];

  const itemsWithSizes = gift.items.filter(
    (i) => i.product.variants.length > 1,
  );

  if (itemsWithSizes.length > 0) {
    steps.push({
      name: "Size",
      description: "What size do you want?",
      component: (props) => <ChooseSizeForm gift={gift} {...props} />,
      isComplete: (v) => (v.items ? v.items.every((v) => v.variant_id) : false),
    });
  }

  return (
    <Wizard
      defaultValues={{
        address: gift.address || undefined,
        items: itemsWithSizes.map((i) => ({
          id: i.id,
          variant_id: i.variant_id || undefined,
        })),
      }}
      isLoading={redeemGift.isLoading}
      onSubmit={redeemGift.mutateAsync}
      steps={steps}
    />
  );
}
